import React, {useEffect, useState} from "react";
import {Avatar, Carousel, Layout, List} from "antd";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {Button, Card, Col, Row, Text, Tooltip} from "@nextui-org/react";
import {useParams} from "react-router-dom";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, deleteDoc, doc, getFirestore} from "firebase/firestore";
import {deleteObject, getStorage, ref as imageRef} from "firebase/storage";
import Firebase from "../../Firebase/Firebase";
import Headerbar from "../../navBars/Headerbar";
import Sidebar from "../../navBars/Sidebar";
import AddContent from "./AddContent";
import {useToast} from "@chakra-ui/react";
import EditCategoryModal from "./EditCategoryModal";
import Firefetch from "../../Firebase/Firefetch";
import EditSection from "./EditSection";
import AddSectionContent from "./AddSectionContent";


const db = getFirestore(Firebase);
const storage = getStorage(Firebase);
const { Content } = Layout;
const AppContent = () => {
    const { id } = useParams();
    const toast = useToast();
    const [collapsed, setCollapsed] = useState(false);
    const [addContentModal, setAddContentModal] = useState<boolean>(false);
    const [addModal, setAddModal] = useState(false);
    const [editCatModal, setEditCatModal] = useState(false);
    const [editSectModal, setEditSectModal] = useState(false);
    const [contentType, setContentType] = useState("");
    const [categoryArray, setCategoryArray] = useState<any[]>([]);
    const [subCategoryArray, setSubCategoryArray] = useState<any[]>([]);
    const [featuredProds, setFeaturedProds] = useState<any[]>([]);

    const [paymentMethodsA, setpaymentMethodsA] = useState<any[]>([]);
    const [systemPageA, setsystemPageA] = useState<any[]>([]);

    const [productArray, setProductArray] = useState<any[]>([]);
    const [bannerArray, setBannerArray] = useState<any[]>([]);
    const [sellerArray, setSellerArray] = useState<any[]>([]);
    const [sectionArray, setSectionArray] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>();

    const [paymentStepsArray, setpaymentStepsArray] = useState<any[]>([]);
    const [parentpayment, setParentpayment] = useState<string>("");
    const [hidepamentsteps, setHidepamentsteps] = useState<boolean>(true);
    const [selectedpayment, setselectedpayment] = useState<any>();
    

    const [selectedSection, setSelectedSection] = useState<any>();
    const [hideSections, setHideSections] = useState<boolean>(true);
    const [hideSubCategory, setHideSubCategory] = useState<boolean>(true);
    const [parentCategory, setParentCategory] = useState<string>("");
    const [categorySnapshot] = useCollection(collection(db, 'categories'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [subCategorySnapshot] = useCollection(collection(db, 'subCategories'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [stepSnapshot] = useCollection(collection(db, 'PaymentSteps'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [sectionSnapshot] = useCollection(collection(db, 'sections'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [bannerSnapshot] = useCollection(collection(db, 'banners'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [featuredSnapshot] = useCollection(collection(db, 'featured_products'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );


    const [pageSnapshot] = useCollection(collection(db, 'SystemPage'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [paymentSnapshot] = useCollection(collection(db, 'PaymentMethods'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );


    const [productSnapshot] = useCollection(collection(db, 'products'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [sellerSnapshot] = useCollection(collection(db, 'sellers'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );


    useEffect(() => {
        var sectArray: any[] = [];
        var catArray: any[] = [];
        var subCatArray: any[] = [];
        var stepArray: any[] = [];
        var featuredArray: any[] = [];
        var paymentMethodsArray: any[] = [];
        var pageArray: any[] = [];
        var prodArray: any[] = [];
        var banArray: any[] = [];
        var sellArray: any[] = [];
        if(sellerSnapshot){
            sellerSnapshot.docs.map((doc: { data: () => any; }) => {
                sellArray.push(doc.data());
            });
            setSellerArray([...sellArray]);
        }
        if(subCategorySnapshot){
            subCategorySnapshot.docs.map((doc: { data: () => any; }) => {
                subCatArray.push(doc.data());
            });
            setSubCategoryArray([...subCatArray]);
        }


        if(stepSnapshot){
            stepSnapshot.docs.map((doc: { data: () => any; }) => {
                stepArray.push(doc.data());
            });
            setpaymentStepsArray([...stepArray]);
        }



        if(categorySnapshot){
            categorySnapshot.docs.map((doc: { data: () => any; }) => {
                catArray.push(doc.data());
            });
            setCategoryArray([...catArray]);
        }


        if(sectionSnapshot){
            sectionSnapshot.docs.map((doc: { data: () => any; }) => {
                sectArray.push(doc.data());
            });
            setSectionArray([...sectArray]);
        }
        if(featuredSnapshot){

            featuredSnapshot.docs.map((doc: { data: () => any; }) => {
                featuredArray.push(doc.data());
            });
            setFeaturedProds([...featuredArray]);
        }


        if(paymentSnapshot){

            paymentSnapshot.docs.map((doc: { data: () => any; }) => {
                paymentMethodsArray.push(doc.data());
            });
            setpaymentMethodsA([...paymentMethodsArray]);
        }

        if(pageSnapshot){

            pageSnapshot.docs.map((doc: { data: () => any; }) => {
                pageArray.push(doc.data());
            });
            setsystemPageA([...pageArray]);
        }

        if(productSnapshot){
            productSnapshot.docs.map((doc: { data: () => any; }) => {
                prodArray.push(doc.data());
            });

            setProductArray([...prodArray]);
        }
        if(bannerSnapshot){
            bannerSnapshot.docs.map((doc: { data: () => any; }) => {
                banArray.push(doc.data());
            });

            setBannerArray([...banArray]);
        }


    }, [id, productSnapshot, bannerSnapshot, featuredSnapshot,
        sectionSnapshot,
        categorySnapshot, sellerSnapshot, subCategorySnapshot, stepSnapshot, paymentSnapshot]);


    const deleteContents = (id: string, type: string) => {
        if(type == "banners"){
            deleteObject(imageRef(storage, `adBanner/${id}`))
                .then(() => {
                    deleteDoc(doc(db, type, id)).then(() => {
                        toast({
                            position: 'top',
                            title: 'Deleted!',
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                    });
            });

        } else {
            deleteDoc(doc(db, type, id)).then(() => {
                toast({
                    position: 'top',
                    title: 'Deleted!',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                });
            });
        }
    }



    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content>
                        <AddSectionContent openDrawer={addContentModal} section={selectedSection}
                                    closeDrawer={setAddContentModal} products={productArray} sellers={sellerArray}/>
                        <AddContent contentType={contentType} openDrawer={addModal}
                                    closeDrawer={setAddModal} products={productArray} parentCategory={parentCategory} parentpayment={parentpayment}/>
                        <EditCategoryModal openModal={editCatModal} closeModal={setEditCatModal}
                                           category={selectedCategory} />
                        <EditSection openModal={editSectModal} closeModal={setEditSectModal}
                                           section={selectedSection} />

                        <div className="mt-4 mx-4">
                            <MDBRow>
                                <MDBCol md="8">
                                    <Card className="font-JosefinSans my-1"
                                          style={{ overflow: 'hidden' }} isHoverable>
                                        <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                            <Text b h5 size={17} className="mt-2">Banners</Text>
                                            <div className="d-flex flex-row">

                                                <Tooltip content={"Add Banner"} rounded color="primary">
                                                    <Button shadow auto className="mx-1" color="primary"
                                                            onClick={() => {
                                                                setAddModal(true);
                                                                setContentType("banners")
                                                            }}
                                                    >
                                                        <i className="pi pi-plus"/>
                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </div>

                                        <Carousel autoplay>
                                            {bannerArray.map((item) => (
                                                <div key={item.id}>
                                                    <Card css={{ bg: "$white", w: "100%" }}>
                                                        <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
                                                            <Col>
                                                                <Tooltip content={"Delete Banner"} rounded color="error">
                                                                    <Button auto
                                                                            onPress={() => {
                                                                                deleteContents(item.id, "banners");
                                                                            }}
                                                                            color="error">
                                                                        <MDBIcon icon="trash-alt" />
                                                                    </Button>
                                                                </Tooltip>

                                                            </Col>
                                                        </Card.Header>
                                                        <Card.Image
                                                            src={item?.imageUrl}
                                                            width="100%"
                                                            height={400}
                                                            objectFit="cover"
                                                            alt={item.id}
                                                        />
                                                    </Card>
                                                </div>

                                            ))}

                                        </Carousel>
                                    </Card>

                                    <Card className="my-2 font-JosefinSans overflow-hidden" isHoverable>
                                        <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                            <Text b h5 size={17} className="mt-2">Featured Products</Text>
                                            <div className="d-flex flex-row">

                                                <Tooltip content={"Add Product"} rounded color="primary">
                                                    <Button shadow auto
                                                            className="mx-1"
                                                            color="primary"
                                                            onPress={() => {
                                                                setAddModal(true);
                                                                setContentType("featured_products")
                                                            }}>
                                                        <i className="pi pi-plus"/>
                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </div>
                                        <Card.Body>
                                            <List
                                                grid={{
                                                    gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 3,
                                                }}
                                                dataSource={featuredProds}
                                                renderItem={item => (
                                                    <List.Item>
                                                        <Card>
                                                            <Card.Body css={{ p: 0 }}>
                                                                <Card.Image
                                                                    src={productArray.find(x => (x.productId) === item.productId)?.images[0]}
                                                                    objectFit="cover"
                                                                    width="100%"
                                                                    height={140}
                                                                    alt={item.title}
                                                                />
                                                            </Card.Body>
                                                            <Card.Footer css={{ justifyItems: "flex-start" }}>
                                                                <Row wrap="wrap" justify="space-between" align="center">
                                                                    <div className="d-flex flex-column">
                                                                        <Text b>{productArray.find(x => (x.productId) === item.productId)?.name}</Text>
                                                                        <Text css={{ color: "$accents7", fontWeight: "$semibold", fontSize: "$sm" }}>
                                                                            {
                                                                                sellerArray.find(x => (x.sellerID) ===
                                                                                    (productArray.find(x => (x.productId) === item.productId)?.sellerId)
                                                                                )?.fullName
                                                                            }
                                                                        </Text>
                                                                    </div>
                                                                    <Tooltip content={"Remove Product"} rounded color="error">
                                                                        <Button auto
                                                                                onPress={() => {
                                                                                    deleteContents(item.id, "featured_products");
                                                                                }}
                                                                                color="error" flat>
                                                                            <MDBIcon icon="trash-alt" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Row>

                                                            </Card.Footer>
                                                        </Card>
                                                    </List.Item>
                                                )}
                                            />
                                        </Card.Body>
                                    </Card>                               
                                    <Card className="my-2 font-JosefinSans overflow-hidden" isHoverable>
                                        <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                            <Text b h5 size={17} className="mt-2">System Pages</Text>
                                            <div className="d-flex flex-row">

                                                <Tooltip content={"Add Instructions"} rounded color="primary">
                                                    <Button shadow auto
                                                            className="mx-1"
                                                            color="primary"
                                                            onPress={() => {
                                                                setAddModal(true);
                                                                setContentType("System Pages")
                                                            }}>
                                                        <i className="pi pi-plus"/>
                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </div>
                                        <Card.Body>
                                            <List
                                                grid={{
                                                    gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 3,
                                                }}
                                                dataSource={systemPageA.filter(category => category.status === "Active")}
                                                renderItem={item => (
                                                    <List.Item>
                                                        <Card>
                                                            <Card.Body css={{ p: 0 }}>
                                                                <Card.Image
                                                                    src={item.imageUrl}
                                                                    objectFit="cover"
                                                                    width="100%"
                                                                    height={140}
                                                                    alt={item.title}
                                                                />
                                                            </Card.Body>
                                                            <Card.Footer css={{ justifyItems: "flex-start" }}>
                                                                <Row wrap="wrap" justify="space-between" align="center">
                                                                    <div className="d-flex flex-column">
                                                                        <Text b>{item.name}</Text>
                                                                        
                                                                    </div>
                                                                    <Tooltip content={"Remove Product"} rounded color="error">
                                                                        <Button auto
                                                                                onPress={() => {
                                                                                    deleteContents(item.id, "SystemPage");
                                                                                }}
                                                                                color="error" flat>
                                                                            <MDBIcon icon="trash-alt" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Row>

                                                            </Card.Footer>
                                                        </Card>
                                                    </List.Item>
                                                )}
                                            />
                                        </Card.Body>
                                    </Card>

                                    <MDBCol md="6">
                                    <Card className="my-1 font-JosefinSans overflow-hidden" isHoverable>
                                        <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                            <Text b h5 size={17} className="mt-2">Payment Methods</Text>
                                            <div className="d-flex flex-row">

                                                <Tooltip content={"Add Payment Methods"} rounded color="primary">
                                                    <Button shadow auto className="mx-1"
                                                            color="primary"
                                                            onPress={() => {
                                                                setAddModal(true);
                                                                setContentType("Payment Methods")
                                                            }}>
                                                        <i className="pi pi-plus"/>
                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </div>
                                        <Card.Body>
                                            <List
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    onChange: page => {
                                                        console.log(page);
                                                    },
                                                    pageSize: 4,
                                                }}
                                                dataSource={paymentMethodsA.filter(category => category.status === "Active")}
                                                renderItem={item => (
                                                    <List.Item
                                                        key={item.id}
                                                        actions={[

                                                            <Tooltip content={"Add Payment Steps"} className="mx-1" rounded color="secondary">
                                                                <Button auto
                                                                        size="xs"
                                                                        color="secondary" flat
                                                                        onPress={() => {
                                                                            setParentpayment(item.id);
                                                                            setAddModal(true);
                                                                            setContentType("Payment Steps");
                                                                        }}>
                                                                    <MDBIcon fas icon="plus" className={"indigo-text"} />
                                                                </Button>
                                                            </Tooltip>,
                                                            <Tooltip content={"show/hide Payment Steps"} className="mx-1" rounded color="secondary">
                                                                <Button auto
                                                                        size="xs"
                                                                        color="secondary" flat
                                                                        onPress={() => {
                                                                            setselectedpayment(item);
                                                                            selectedpayment == item && setHidepamentsteps(!hidepamentsteps);
                                                                        }}>
                                                                    { hidepamentsteps && selectedpayment === item ?
                                                                        <MDBIcon fas icon="angle-double-right" />
                                                                        :
                                                                        <MDBIcon fas icon="angle-double-down" />
                                                                    }

                                                                </Button>
                                                            </Tooltip>
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <Avatar
                                                                    size={50}
                                                                    shape="square"
                                                                    src={item.imageUrl}
                                                                    style={{ color: '#29327b', backgroundColor: '#ecf7fc' }}/>
                                                            }
                                                            title={
                                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                                <b>{item.name}</b>
                                                                <div className="d-flex flex-row">


                                                                    {/* <Tooltip content={"edit Payment Method"} className="mx-1" rounded color="primary">
                                                                        <Button auto
                                                                                color="primary" flat
                                                                                onPress={() => {
                                                                                    setselectedpayment(item);
                                                                                    
                                                                                    setEditCatModal(true);
                                                                                }}>
                                                                            <MDBIcon fas icon="marker" />
                                                                        </Button>
                                                                    </Tooltip> */}
                                                                    <Tooltip content={"delete Payment Method"} className="mx-1" rounded color="error">
                                                                        <Button auto
                                                                                color="error" flat
                                                                                onPress={() => {
                                                                                    //deleteContents(item.id, "categories");
                                                                                    Firefetch.DeleteFromDB("PaymentMethods", item.id)
                                                                                }}>
                                                                            <MDBIcon icon="trash-alt" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>

                                                            </div>
                                                            }
                                                        />
                                                        {
                                                            (!hidepamentsteps    && selectedpayment === item) ?
                                                                <List
                                                                    itemLayout="horizontal"
                                                                    dataSource={paymentStepsArray.filter(c => c.PaymentMethodID === item.id && c.status === "Active")}
                                                                    pagination={{
                                                                        pageSize: 5,
                                                                    }}
                                                                    renderItem={ (subcat) => (
                                                                        <List.Item>
                                                                            <List.Item.Meta
                                                                               // avatar={<Avatar src={subcat.imageUrl} />}
                                                                                title={
                                                                                <div className="d-flex flex-row justify-content-between">
                                                                                    <p>{subcat.name}</p>
                                                                                    {/* <Tooltip content={"delete Payment Step"} className="mx-1" rounded color="error">
                                                                                        <Button auto
                                                                                                size="sm"
                                                                                                color="error" flat
                                                                                                onPress={() => {
                                                                                                    Firefetch.DeleteFromDB("PaymentSteps", item.id)
                                                                                                }}>
                                                                                            <MDBIcon icon="trash-alt" />
                                                                                        </Button>
                                                                                    </Tooltip> */}
                                                                                </div>
                                                                            }
                                                                            />
                                                                        </List.Item>
                                                                    )}
                                                                />
                                                                :
                                                                null
                                                        }

                                                    </List.Item>

                                                )}
                                            />
                                        </Card.Body>
                                    </Card>

                                        </MDBCol>
                                       
                                </MDBCol>
                                
                                <MDBCol md="4">
                                    <Card className="my-1 font-JosefinSans overflow-hidden" isHoverable>
                                        <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                            <Text b h5 size={17} className="mt-2">Categories</Text>
                                            <div className="d-flex flex-row">

                                                <Tooltip content={"Add Category"} rounded color="primary">
                                                    <Button shadow auto className="mx-1"
                                                            color="primary"
                                                            onPress={() => {
                                                                setAddModal(true);
                                                                setContentType("categories")
                                                            }}>
                                                        <i className="pi pi-plus"/>
                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </div>
                                        <Card.Body>
                                            <List
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    onChange: page => {
                                                        console.log(page);
                                                    },
                                                    pageSize: 4,
                                                }}
                                                dataSource={categoryArray.filter(category => category.status === "Active")}
                                                renderItem={item => (
                                                    <List.Item
                                                        key={item.id}
                                                        actions={[

                                                            <Tooltip content={"Add sub-category"} className="mx-1" rounded color="secondary">
                                                                <Button auto
                                                                        size="xs"
                                                                        color="secondary" flat
                                                                        onPress={() => {
                                                                            setParentCategory(item.id);
                                                                            setAddModal(true);
                                                                            setContentType("sub-categories");
                                                                        }}>
                                                                    <MDBIcon fas icon="plus" className={"indigo-text"} />
                                                                </Button>
                                                            </Tooltip>,
                                                            <Tooltip content={"show/hide sub-category"} className="mx-1" rounded color="secondary">
                                                                <Button auto
                                                                        size="xs"
                                                                        color="secondary" flat
                                                                        onPress={() => {
                                                                            setSelectedCategory(item);
                                                                            selectedCategory == item && setHideSubCategory(!hideSubCategory);
                                                                        }}>
                                                                    { hideSubCategory && selectedCategory === item ?
                                                                        <MDBIcon fas icon="angle-double-right" />
                                                                        :
                                                                        <MDBIcon fas icon="angle-double-down" />
                                                                    }

                                                                </Button>
                                                            </Tooltip>
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <Avatar
                                                                    size={50}
                                                                    shape="square"
                                                                    src={item.imageUrl}
                                                                    style={{ color: '#29327b', backgroundColor: '#ecf7fc' }}/>
                                                            }
                                                            title={
                                                            <div className="d-flex flex-row justify-content-between align-items-center">
                                                                <b>{item.name}</b>
                                                                <div className="d-flex flex-row">


                                                                    <Tooltip content={"edit category"} className="mx-1" rounded color="primary">
                                                                        <Button auto
                                                                                color="primary" flat
                                                                                onPress={() => {
                                                                                    setSelectedCategory(item);
                                                                                    setEditCatModal(true);
                                                                                }}>
                                                                            <MDBIcon fas icon="marker" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip content={"delete category"} className="mx-1" rounded color="error">
                                                                        <Button auto
                                                                                color="error" flat
                                                                                onPress={() => {
                                                                                    //deleteContents(item.id, "categories");
                                                                                    Firefetch.DeleteFromDB("categories", item.id)
                                                                                }}>
                                                                            <MDBIcon icon="trash-alt" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>

                                                            </div>
                                                            }
                                                        />
                                                        {
                                                            (!hideSubCategory && selectedCategory === item) ?
                                                                <List
                                                                    itemLayout="horizontal"
                                                                    dataSource={subCategoryArray.filter(c => c.categoryID === item.id && c.status === "Active")}
                                                                    pagination={{
                                                                        pageSize: 5,
                                                                    }}
                                                                    renderItem={ (subcat) => (
                                                                        <List.Item>
                                                                            <List.Item.Meta
                                                                                avatar={<Avatar src={subcat.imageUrl} />}
                                                                                title={
                                                                                <div className="d-flex flex-row justify-content-between">
                                                                                    <p>{subcat.name}</p>
                                                                                    <Tooltip content={"delete subCategory"} className="mx-1" rounded color="error">
                                                                                        <Button auto
                                                                                                size="sm"
                                                                                                color="error" flat
                                                                                                onPress={() => {
                                                                                                    Firefetch.DeleteFromDB("subCategories", item.id)
                                                                                                }}>
                                                                                            <MDBIcon icon="trash-alt" />
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            }
                                                                            />
                                                                        </List.Item>
                                                                    )}
                                                                />
                                                                :
                                                                null
                                                        }

                                                    </List.Item>

                                                )}
                                            />
                                        </Card.Body>
                                    </Card>


                                    <Card className="my-2 font-JosefinSans overflow-hidden" isHoverable>
                                        <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                            <Text b h5 size={17} className="mt-2">Sections</Text>
                                            <div className="d-flex flex-row">

                                                <Tooltip content={"Add Section"} rounded color="primary">
                                                    <Button shadow auto className="mx-1"
                                                            color="primary"
                                                            onPress={() => {
                                                                setAddModal(true);
                                                                setContentType("sections")
                                                            }}>
                                                        <i className="pi pi-plus"/>
                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </div>
                                        <Card.Body>
                                            <List
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    pageSize: 5,
                                                }}
                                                dataSource={sectionArray.filter(section => section.status === "Active")}
                                                renderItem={item => (
                                                    <List.Item
                                                        key={item.id}
                                                        actions={[

                                                            <Tooltip content={"Add content"} className="mx-1" rounded color="secondary">
                                                                <Button auto
                                                                        size="xs"
                                                                        color="secondary" flat
                                                                        onPress={() => {
                                                                            setSelectedSection(item);
                                                                            setAddContentModal(true);
                                                                        }}>
                                                                    <MDBIcon fas icon="plus" className={"indigo-text"} />
                                                                </Button>
                                                            </Tooltip>,
                                                            <Tooltip content={"show/hide content"} className="mx-1" rounded color="secondary">
                                                                <Button auto
                                                                        size="xs"
                                                                        color="secondary" flat
                                                                        onPress={() => {
                                                                            setSelectedSection(item);
                                                                            selectedSection == item && setHideSections(!hideSections);
                                                                        }}>
                                                                    { hideSections && selectedSection === item ?
                                                                        <MDBIcon fas icon="angle-double-right" />
                                                                        :
                                                                        <MDBIcon fas icon="angle-double-down" />
                                                                    }

                                                                </Button>
                                                            </Tooltip>
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <Avatar
                                                                    size={50}
                                                                    shape="square"
                                                                    src={item.imageUrl}
                                                                    style={{ color: '#29327b', backgroundColor: '#ecf7fc' }}/>
                                                            }
                                                            title={
                                                                <div className="d-flex flex-row justify-content-between align-items-center">
                                                                    <b>{item.name}</b>
                                                                    <div className="d-flex flex-row">


                                                                        <Tooltip content={"edit section"} className="mx-1" rounded color="primary">
                                                                            <Button auto
                                                                                    color="primary" flat
                                                                                    onPress={() => {
                                                                                        setSelectedSection(item);
                                                                                        setEditSectModal(true);
                                                                                    }}>
                                                                                <MDBIcon fas icon="marker" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                        <Tooltip content={"hide/delete section"} className="mx-1" rounded color="error">
                                                                            <Button auto
                                                                                    color="error" flat
                                                                                    onPress={() => {
                                                                                        Firefetch.DeleteFromDB("sections", item.id)
                                                                                    }}>
                                                                                <MDBIcon icon="trash-alt" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </div>

                                                                </div>
                                                            }
                                                        />
                                                        {
                                                            (!hideSections && selectedSection === item) ?
                                                                <List
                                                                    itemLayout="horizontal"
                                                                    dataSource={item.content}
                                                                    pagination={{
                                                                        pageSize: 5,
                                                                    }}
                                                                    renderItem={ (content: any, index: number) => {
                                                                        var contentObject = {
                                                                            imageUrl: "",
                                                                            name: ""
                                                                        };
                                                                        if(item.contentType == "Images"){
                                                                            contentObject.imageUrl = content.item;
                                                                            contentObject.name = `image-${index+1}`;

                                                                        } else if(item.contentType == "Products"){
                                                                            var product = productArray.find(x => x.productId == content.item);
                                                                            contentObject.imageUrl = product?.imageUrl;
                                                                            contentObject.name = product?.name;

                                                                        } else if(item.contentType == "Sellers"){
                                                                            var seller = sellerArray.find(x => x.sellerID == content.item);
                                                                            contentObject.imageUrl = seller?.imageUrl;
                                                                            contentObject.name = seller?.fullName;
                                                                        }
                                                                        return (
                                                                            <List.Item>
                                                                                <List.Item.Meta
                                                                                    avatar={<Avatar shape="square" src={contentObject.imageUrl} />}
                                                                                    title={
                                                                                        <div className="d-flex flex-row justify-content-between">
                                                                                            <p>{contentObject.name}</p>
                                                                                            <Tooltip content={"delete content"} className="mx-1" rounded color="error">
                                                                                                <Button auto
                                                                                                        size="sm"
                                                                                                        color="error" flat
                                                                                                        onPress={() => {

                                                                                                            item.content = item.content.filter((x: { item: any; }) => x.item !== content.item);
                                                                                                            Firefetch.UpdateInDB("sections", item.id, item).then((result) => {
                                                                                                                if(result == "success"){
                                                                                                                    toast({
                                                                                                                        position: 'top',
                                                                                                                        title: 'Deleted!',
                                                                                                                        status: 'success',
                                                                                                                        duration: 3000,
                                                                                                                        isClosable: true,
                                                                                                                    });
                                                                                                                }
                                                                                                            });


                                                                                                        }}>
                                                                                                    <MDBIcon icon="trash-alt" />
                                                                                                </Button>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    }
                                                                                />
                                                                            </List.Item>
                                                                        )
                                                                    } }
                                                                />
                                                                :
                                                                null
                                                        }

                                                    </List.Item>

                                                )}
                                            />
                                        </Card.Body>
                                    </Card>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}

export default AppContent;
