import { initializeApp } from 'firebase/app';

var firebaseConfig = {
    apiKey: "AIzaSyB8_-cTGVbCqGfhcQfHP34u1OFH1bDW7fg",
    authDomain: "shoppedmw-app.firebaseapp.com",
    databaseURL: "https://shoppedmw-app-default-rtdb.firebaseio.com",
    projectId: "shoppedmw-app",
    storageBucket: "shoppedmw-app.appspot.com",
    messagingSenderId: "671339211471",
    appId: "1:671339211471:web:bbde9409aeb9b3f174a5ba",
    measurementId:"G-2K02J1FJXF"
};
// Initialize Firebase
const fire = initializeApp(firebaseConfig, 'main');
export default fire;
