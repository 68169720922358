import React, {useEffect, useState} from 'react';
import {Layout} from "antd";
import Headerbar from "../../navBars/Headerbar";
import Sidebar from "../../navBars/Sidebar";
import {Card, Container, Input, Text} from "@nextui-org/react";
import {Card as ProdCard} from "antd";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import { Grid, Loading } from "@nextui-org/react";
import {useCollection} from "react-firebase-hooks/firestore";
import {collection, getFirestore} from "firebase/firestore";
import Firebase from "../../Firebase/Firebase";
import {generatePath, useNavigate} from "react-router-dom";


interface listType {
    id: string;
    title: string,
    img: string,
    price: string
}  

const { Content } = Layout;
const db = getFirestore(Firebase);
const Products = () => {
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [productList, setProductList] = useState<listType[]>([]);
    const [dataArray, setDataArray] = useState<listType[]>([]);
    const [productSnapshot, loading] = useCollection(collection(db, 'products'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    function numberWithCommas(x: string) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
   
    useEffect(() => {
        if(productSnapshot){
            var tempArray: any[] = [];
            productSnapshot.docs.map((doc: { data: () => any; }) => {
                tempArray.push(doc.data());
            });
            var emptyArray: listType[] = [];
            tempArray.map((item, index) => {
                emptyArray.push({
                    id: item.productId,
                    title: item.name,
                    img: item.images[0],
                    price: item.price,
                });
            });
            setProductList([...emptyArray]);
            setDataArray([...emptyArray]);
        }
    }, [productSnapshot]);

    const searchFilter = (searchText : string) => {
        searchText = searchText.toLowerCase();

        var filteredArray = dataArray.filter(({title, price}) => {
            title = title.toLowerCase();
            price = price.toLowerCase();

            return title?.includes(searchText) || price?.includes(searchText);
        });

        setProductList([...filteredArray]);
    }
    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content>
                        <Container className="mt-2">
                            <MDBRow>
                                <MDBCol>
                                    <Card className="font-JosefinSans overflow-hidden">
                                        <div className="d-flex pt-2 px-2 justify-content-between align-item-center">
                                            <Text h4 size={23}
                                                  css={{ color: "#29327b", fontWeight: "$bold", margin: 10 }}>
                                                Products
                                            </Text>
                                            <Input
                                                placeholder="search products"
                                                onChange={(e) => { searchFilter(String(e.target.value))}}
                                                className="mx-1"
                                                contentRight={<MDBIcon icon="search"/>}
                                            />
                                        </div>

                                    </Card>
                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    { loading ?
                                        <div className="d-flex flex-row align-item-center justify-content-center mt-5">
                                            <Loading/>
                                        </div>

                                        :
                                        <Grid.Container gap={2} justify="flex-start">
                                            {productList.map((item, index) => (
                                                <Grid xs={6} sm={3} key={index}>
                                                    <ProdCard
                                                        onClick={() => {
                                                            navigate(
                                                                generatePath('/products/:id', {id: item.id})
                                                            )
                                                        }}
                                                        hoverable
                                                        style={{ width: "100%" }}
                                                        cover={
                                                            <Card.Image
                                                                src={item.img}
                                                                objectFit="cover"
                                                                width="100%"
                                                                height={240}
                                                                alt={item.title}
                                                            />
                                                        }
                                                    >
                                                        <div className="font-JosefinSans">
                                                            <b>
                                                                {item.title}
                                                            </b>
                                                            <p className="font-weight-lighter">
                                                                {`MK${numberWithCommas(item.price)}`}
                                                            </p>
                                                        </div>
                                                    </ProdCard>

                                                </Grid>
                                            ))}
                                        </Grid.Container>
                                    }


                                </MDBCol>
                            </MDBRow>
                        </Container>
                    </Content>
                </Layout>
            </Layout>

        </>
    )
}

export default Products;
