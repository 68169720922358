import React, {useEffect, useState} from 'react';
import {Carousel, Layout} from 'antd';
import Sidebar from "../../navBars/Sidebar";
import Headerbar from "../../navBars/Headerbar";
import {Card, Dropdown, Spacer, Text} from "@nextui-org/react";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import TopSellingProducts from "./Charts/TopSellingProducts";
import  SystemUsersPieChart  from './Charts/SystemUsersPieChart';
import {getFirestore, collection} from "firebase/firestore";
import Firebase from "../../Firebase/Firebase";
import {useNavigate} from "react-router-dom";
import {useCollection} from "react-firebase-hooks/firestore";
import TopSellers from "./Charts/TopSellers";
import TopViewedProducts from "./Charts/TopViewedProducts";
import TopViewedSellers from "./Charts/TopViewedSellers";

var db = getFirestore(Firebase);
const { Content } = Layout;
function Home () {

    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const [sellerSnapshot] = useCollection(collection(db, 'users'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [transSnapshot] = useCollection(collection(db, 'order'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [buyerSnapshot] = useCollection(collection(db, 'users'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [productSnapshot] = useCollection(collection(db, 'products'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [bannerSnapshot] = useCollection(collection(db, 'banners'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [logSnapshot] = useCollection(collection(db, 'logs'),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [logsArray, setLogsArray] = useState<any[]>([]);
    const [bannerArray, setBannerArray] = useState<any[]>([]);
    const [sellingProducts, setSellingProducts] = useState<any[]>([]);
    const [topSellers, setTopSellers] = useState<any[]>([]);
    const [sellerArray, setSellerArray] = useState<any[]>([]);
    const [productArray, setProductArray] = useState<any[]>([]);
    const [userArray, setUserArray] = useState<any[]>([]);
    const [transArray, setTransArray] = useState<any[]>([]);
    const [topToggle1, setToggle1] = useState<number>(5);
    const [topToggle2, setToggle2] = useState<number>(5);

    const list = [
        {
            title: "Sellers",
            url: "/shops",
            icon:<MDBIcon icon={'shopping-cart'} style={{color: "#29327b"}} size="2x" className="mb-2"/>,
            price: sellerArray.filter(x => x.Type === "Seller").length,

        },
        {
            title: "Transactions",
            url: "/transactions",
            icon:<MDBIcon icon={'money-bill-alt'} style={{color: "#29327b"}} size="2x" className="mb-2"/>,
            price: transArray.length,
        },
        {
            title: "Buyers",
            url: "/buyers",
            icon:<MDBIcon icon={'users'} size="2x" style={{color: "#29327b"}} className="mb-2"/>,
            price: userArray.filter(x => x.Type === "Buyer").length,
        },
        {
            title: "Products",
            url: "/products",
            icon:<MDBIcon icon={'shopping-bag'} style={{color: "#29327b"}} size="2x" className="mb-2"/>,
            price: productArray.length,
        }
    ];

    function onlyUnique(value: any, index: any, self: string | any[]) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        var logs: any[] = [];
        if(logSnapshot){
            logSnapshot.docs.map((doc: { data: () => any; }) => {
                logs.push(doc.data());
            });
            setLogsArray([...logs]);
        }

        var sellers: any[] = [];
        sellerSnapshot?.docs.map((doc: { data: () => any; }) => {
            sellers.push(doc.data());
        });
        setSellerArray(sellers);

        var buyers: any[] = [];
        buyerSnapshot?.docs.map((doc: { data: () => any; }) => {
            buyers.push(doc.data());
        });
        setUserArray(buyers);

        var products: any[] = [];
        productSnapshot?.docs.map((doc: { data: () => any; }) => {
            products.push(doc.data());
        });
        setProductArray(products);

        var transactions: any[] = [];
        transSnapshot?.docs.map((doc: { data: () => any; }) => {
            transactions.push(doc.data());
        });
        setTransArray(transactions);

        var banArray: any[] = [];
        bannerSnapshot?.docs.map((doc: { data: () => any; }) => {
            banArray.push(doc.data());
        });
        setBannerArray([...banArray]);


        var tempArray2: any[] = [];
        sellers.map((seller) => {
            var sellerOrders = transactions.filter(x => x.sellerId === seller.sellerID);
            //var result = sellerOrders.reduce(function (acc, obj) { return acc + parseInt(obj.quantity); }, 0);

            tempArray2.push(
                {
                    sellerId: seller.sellerID,
                    seller: <a>{seller.fullName}</a>,
                    orders: sellerOrders.length,
                    sold: sellerOrders.filter(x => (x.orderStatus === "delivered" || x.orderStatus === "completed")).length
                }
            )
        });
        setTopSellers([...tempArray2]);

        var tempArray: any[] = [];
        products.map((product) => {
            var prodOrders = transactions.filter(x => x.productId == product.productId);
            var result = prodOrders.reduce(function (acc, obj) { return acc + parseInt(obj.quantity); }, 0);

            var peopleArray: string[] = [];
            prodOrders.forEach((obj) => { peopleArray.push(obj.buyerId) });

            peopleArray = peopleArray.filter(onlyUnique);

            tempArray.push(
                {
                    productId: product.productId,
                    name: product.name,
                    seller: sellers.find(x => (x.sellerID) === product.sellerId)?.fullName,
                    units: result,
                    buyers : peopleArray.length
                }
            );
        });

        tempArray = tempArray.filter(x => x.units != 0);
        setSellingProducts([...tempArray]);



    }, [transSnapshot, sellerSnapshot, buyerSnapshot, productSnapshot, bannerSnapshot, logSnapshot]);



    return (
        <Layout style={{ minHeight: '100vh' }}>

            <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
            <Layout className="site-layout">
                <Sidebar collapsed={collapsed}/>
                <Content>

                    <div className="mt-4 mx-3">
                        <MDBRow>
                            <MDBCol md="5">
                                <MDBRow>
                                    {list.map((item) => (
                                        <MDBCol>
                                            <Card className="my-1 font-JosefinSans" isHoverable isPressable onPress={() => {
                                                navigate(item.url);
                                            }}>
                                                <Card.Body>
                                                    <div className="d-flex flex-column justify-content-start px-1">
                                                        {item.icon}
                                                        <Text b h5 size={15} className="mt-1">{item.title}</Text>
                                                        <Text h4 size={21} className="mt-3"
                                                              css={{ color: "#29327b", fontWeight: "$bold" }}>{item.price}
                                                        </Text>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </MDBCol>

                                    ))}
                                </MDBRow>
                                <Spacer y={0.3}/>
                                <Card className="my-2 font-JosefinSans overflow-hidden">
                                    <Card.Header>
                                        <Text b h5 size={17} className="m-2">System Users</Text>
                                    </Card.Header>
                                    <Card.Body>
                                        <SystemUsersPieChart
                                            sellers={sellerArray.length}
                                            buyers={userArray.filter(x => x.userRole === "buyer").length}
                                            admins={userArray.filter(x => x.userRole === "Admin").length}/>
                                    </Card.Body>
                                </Card>
                                <Card className="my-2 font-JosefinSans overflow-hidden">
                                    <Card.Header>
                                        <Text b h5 size={17} className="m-2">Top Sellers</Text>
                                    </Card.Header>
                                    <Card.Body>
                                        <TopSellers data={topSellers}/>
                                    </Card.Body>
                                </Card>

                                <Card className="my-2 font-JosefinSans overflow-hidden">
                                    <>

                                        <div className="d-flex flex-row w-100 justify-content-between pt-3 px-3">
                                            <Text b h5 size={17} className="m-2">Top Viewed Sellers</Text>

                                            <Dropdown>
                                                <Dropdown.Button flat>Change</Dropdown.Button>
                                                <Dropdown.Menu aria-label="Dynamic Actions"
                                                               onAction={(key) => {
                                                                   setToggle1(parseInt(key.toString()));
                                                               }}
                                                               items={[
                                                    { key: 5, name: "Top 5" },
                                                    { key: 10, name: "Top 10" },
                                                    { key: 15, name: "Top 15" },
                                                ]}>
                                                    {(item:any) => (
                                                        <Dropdown.Item
                                                            key={item.key}

                                                        >
                                                            {item.name}
                                                        </Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                    </>
                                    <Card.Body>
                                        <TopViewedSellers logs={logsArray} sellers={sellerArray} toggleNumber={topToggle1}/>
                                    </Card.Body>
                                </Card>

                            </MDBCol>
                            <MDBCol md="7">
                                <Card className="my-2 font-JosefinSans" isHoverable isPressable
                                      style={{ overflow: 'hidden'}}
                                      onPress={() => {
                                          navigate("/content");
                                      }}>
                                    <Card.Header css={{ position: "absolute", zIndex: 1, top: 5 }}>
                                        <Text b h5 size={17} className="m-2">Banners</Text>
                                    </Card.Header>
                                    <Carousel autoplay>
                                        {bannerArray.map((item) => (
                                            <div key={item.id}>
                                                <Card css={{ bg: "$white", w: "100%" }}>
                                                    <Card.Image
                                                        src={item?.imageUrl}
                                                        width="100%"
                                                        height={500}
                                                        objectFit="cover"
                                                        alt={item.id}
                                                    />
                                                </Card>
                                            </div>

                                        ))}

                                    </Carousel>
                                </Card>
                                <Card className="my-2 font-JosefinSans overflow-hidden">
                                    <Card.Header>
                                        <Text b h5 size={17} className="m-2">Top Selling Products</Text>
                                    </Card.Header>
                                    <Card.Body>
                                        <TopSellingProducts data={sellingProducts}/>
                                    </Card.Body>
                                </Card>
                                <Card className="my-2 font-JosefinSans overflow-hidden">
                                    <>

                                        <div className="d-flex flex-row w-100 justify-content-between pt-3 px-3">
                                            <Text b h5 size={17} className="m-2">Top Viewed Products</Text>

                                            <Dropdown>
                                                <Dropdown.Button flat>Change</Dropdown.Button>
                                                <Dropdown.Menu aria-label="Dynamic Actions"
                                                               onAction={(key) => {
                                                                   setToggle2(parseInt(key.toString()));
                                                               }}
                                                               items={[
                                                                   { key: 5, name: "Top 5" },
                                                                   { key: 10, name: "Top 10" },
                                                                   { key: 15, name: "Top 15" },
                                                               ]}>
                                                    {(item:any) => (
                                                        <Dropdown.Item
                                                            key={item.key}

                                                        >
                                                            {item.name}
                                                        </Dropdown.Item>
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                    </>
                                    <Card.Body>
                                        <TopViewedProducts logs={logsArray} products={productArray} toggleNumber={topToggle2}/>
                                    </Card.Body>
                                </Card>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default Home;
