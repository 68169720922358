import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Headerbar from "../../navBars/Headerbar";
import {Avatar, Layout, List} from "antd";
import Sidebar from "../../navBars/Sidebar";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {Grid, Card, Spacer, Text, Tooltip, Button} from "@nextui-org/react";
import {Alert, useToast} from "@chakra-ui/react";
import TransactionChart from "../Buyers/TransactionChart";
import {useCollection, useDocumentData} from "react-firebase-hooks/firestore";
import {collection, doc, getFirestore, query, updateDoc, where} from "firebase/firestore";
import Firebase from "../../Firebase/Firebase";
import { Tag } from 'primereact/tag';
import moment from "moment";
import TransactionTable from './TransactionTable';
import {Rating} from "primereact/rating";
import EditSeller from "../../Drawers/Seller/EditSeller";


const db = getFirestore(Firebase);
const { Content } = Layout;
const SellerDetails = () => {
    const { id } = useParams();
    const toast = useToast();
    const [collapsed, setCollapsed] = useState(false);
    const [seller, setSeller] = useState<any>();
    const [editModal, setEditModal] = useState<boolean>(false);
    const [reviewArray, setReviewArray] = useState<any[]>([]);
    const [productArray, setProductArray] = useState<any[]>([]);
    const [transactionsArray, setTransactionsArray] = useState<any[]>([]);
    const [logsArray, setLogsArray] = useState<any[]>([]);
    const [ordersArray, setOrdersArray] = useState<any[]>([]);
    const [userArray, setUserArray] = useState<any[]>([]);
    const [followArray, setFollowArray] = useState<any[]>([]);
    const [sellerArray, setSellerArray] = useState<any[]>([]);
    const [value] = useDocumentData(
        doc(db, "users", `${id?.trim()}`),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [reviewSnapshot] = useCollection(query(collection(db, 'reviews'), where("objectId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [productSnapshot] = useCollection(query(collection(db, 'products'), where("sellerId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [orderSnapshot] = useCollection(query(collection(db, 'order'), where("sellerId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [logSnapshot] = useCollection(query(collection(db, 'logs'), where("sellerId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [buyerSnapshot] = useCollection(query(collection(db, 'users'), where("Type", "==", `Buyer`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [followSnapshot] = useCollection(query(collection(db, 'follows'), where("sellerId", "==", `${id?.trim()}`)),
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
 
    const [sellerSnapshot] = useCollection(query(collection(db, 'users'), where("Type", "==", `Seller`)),
    {
        snapshotListenOptions: { includeMetadataChanges: true },
    }
);

    const list = [
        {
            title: "Products",
            url: "/products",
            icon:<MDBIcon icon={'shopping-bag'} style={{color: "#29327b"}} size="2x" className="mb-2"/>,
            number: productArray.length,

        },
        {
            title: "Followers",
            url: "/followers",
            icon:<MDBIcon icon={'thumbs-up'} style={{color: "#29327b"}} size="2x" className="mb-2"/>,
            number: followArray.length,
        },
        {
            title: "Reviews",
            url: "/reviews",
            icon:<MDBIcon icon={'star'} size="2x" style={{color: "#29327b"}} className="mb-2"/>,
            number: reviewArray.length,
        },
        {
            title: "Sales",
            url: "/sales",
            icon:<MDBIcon icon={'money-bill'} style={{color: "#29327b"}} size="2x" className="mb-2"/>,
            number: ordersArray.length,
            //.filter(order => (order.orderStatus === "Confirmed"))
        }
    ];

    function numberWithCommas(x: string) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    useEffect(() => {
        if(value) {
            setSeller(value);
        }

        var logs: any[] = [];
        var prodArray: any[] = [];
        var tempArray: any[] = [];
        var orders: any[] = [];
        var buyerArray: any[] = [];
        var folArray: any[] = [];

        var sellers: any[] = [];
        sellerSnapshot?.docs.map((doc: { data: () => any; }) => {
            sellers.push(doc.data());
        });
        setSellerArray(sellers);

        if(followSnapshot){
            followSnapshot.docs.map((doc: { data: () => any; }) => {
                folArray.push(doc.data());
            });
            var filtered = folArray.filter(x => x.buyerId !== "");
            setFollowArray([...filtered]);
        }

        if(buyerSnapshot){
            buyerSnapshot.docs.map((doc: { data: () => any; }) => {
                buyerArray.push(doc.data());
            });
            setUserArray([...buyerArray]);
        }

        if(productSnapshot){
            productSnapshot.docs.map((doc: { data: () => any; }) => {
                prodArray.push(doc.data());
            });
            setProductArray([...prodArray]);
        }
        if(reviewSnapshot){
            reviewSnapshot.docs.map((doc: { data: () => any; }) => {
                tempArray.push(doc.data());
            });

            setReviewArray([...tempArray]);
        }
        if(orderSnapshot){
            orderSnapshot.docs.map((doc: { data: () => any; }) => {
                orders.push(doc.data());
            });
            var orderArray: any[] = [];
            orders.map((item) => {
                orderArray.push({
                    product: prodArray.find(x => (x.productId) === item.productId)?.name,
                    amount: `MK ${numberWithCommas(item.totalAmount)}`,
                    buyer: buyerArray.find(x => (x.userID) === item.buyerId)?.Fullname === undefined ?
                        sellers.find(x => (x.sellerID) === item.buyerId)?.Fullname :
                        buyerArray.find(x => (x.userID) === item.buyerId)?.Fullname
                    ,
                    status: <Tag className="mx-2"
                                 severity={(item.orderStatus == "Pending") ? "warning" : ((item.orderStatus == "Comfirmed") ? "success" : "info")}
                                 value={item.orderStatus}></Tag>,
                    date: item.createdAt,
                    payment : item.paymentMethods
                });
            });

            setOrdersArray([...orders]);
            setTransactionsArray([...orderArray]);
        }

        if(logSnapshot){
            logSnapshot.docs.map((doc: { data: () => any; }) => {
                logs.push(doc.data());
            });
            setLogsArray([...logs]);
        }


    }, [id, value, reviewSnapshot, buyerSnapshot, orderSnapshot, logSnapshot, productSnapshot, sellerSnapshot, followSnapshot])

    const changeStatus = (status: string) => {
        const docRef = doc(db, "sellers", `${id?.trim()}`);
        updateDoc(docRef, {
            sellerStatus: status
        }).then(() => {
            toast({
                position: 'top',
                title: 'Seller status changed!',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        })
    }

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>

                <Headerbar collapsed={collapsed} setCollapsed={setCollapsed}/>
                <Layout className="site-layout">
                    <Sidebar collapsed={collapsed}/>
                    <Content>

                        <div className="mt-4 mx-4">
                            <EditSeller seller={seller} openDrawer={editModal} closeDrawer={setEditModal}/>
                            <MDBRow>
                                <MDBCol md="9">
                                    <div className="d-flex flex-row my-1">
                                        <Card css={{ bg: "$black", w: "100%" }}>
                                            <Card.Image
                                                src={seller?.userImage}
                                                width="100%"
                                                height={310}
                                                objectFit="cover"
                                                alt="Card image background"
                                            />
                                        </Card>
                                        <Card className="font-JosefinSans my-1"
                                              style={{ overflow: 'hidden' }} >
                                            <div className="d-flex justify-content-between align-item-center m-2 p-1">
                                                <b>Seller Details</b>
                                                <div className="d-flex flex-row">
                                                    <Tooltip content={"Change status"} rounded color="primary">
                                                        <Button shadow rounded
                                                                auto
                                                                size="xs" className="mx-1" color="primary" onClick={() => {
                                                            var status: string;
                                                            if(seller.sellerStatus === "active" || seller.sellerStatus === "Active"){
                                                                status = "disabled";
                                                            } else {
                                                                status = "Active";
                                                            }

                                                            changeStatus(status);
                                                        }}>
                                                            <i className="pi pi-replay"/>
                                                        </Button>
                                                    </Tooltip>

                                                    <Tooltip content={"Edit Seller"} rounded color="primary">
                                                        <Button shadow rounded size="xs"
                                                                auto
                                                                className="mx-1" color="primary" onClick={() => {
                                                            setEditModal(true);

                                                        }}>
                                                            <i className="pi pi-pencil m-1"/>
                                                        </Button>
                                                    </Tooltip>

                                                </div>
                                            </div>

                                            <div className="px-2 m-1">
                                                <Alert status='info' variant='left-accent' className="my-1">Name: {seller?.Fullname}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Phone: {seller?.Phonenumber}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Email: {seller?.Email}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Location: {seller?.Location}</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Payment: Tnm Mpamba</Alert>
                                                <Alert status='info' variant='left-accent' className="my-1">Status:
                                                    <Tag className="mx-2" severity={seller?.sellerStatus === "Active" ? "success" : "warning"}
                                                         value='Active'></Tag>
                                                    </Alert>
                                            </div>
                                        </Card>
                                    </div>
                                </MDBCol>
                                <MDBCol md="3">
                                    <Grid.Container gap={1} justify="flex-start" className="h-100">
                                        {list.map((item, index) => (
                                            <Grid xs={6} key={index}>
                                                <Card className="my-1 font-JosefinSans h-100">
                                                    <Card.Body>
                                                        <div className="d-flex flex-column justify-content-start px-2">
                                                            {item.icon}
                                                            <Text b h5 size={15} className="mt-1">{item.title}</Text>
                                                            <Text h4 size={21} className="mt-4"
                                                                  css={{ color: "#29327b", fontWeight: "$bold" }}>{item.number}
                                                            </Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Grid>

                                        ))}
                                    </Grid.Container>

                                </MDBCol>
                            </MDBRow>

                            <Spacer y={1}/>
                            <MDBRow>
                                <MDBCol md="8">
                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <Card.Header>
                                            <Text b h5 size={17} className="m-2">Seller Activity</Text>
                                        </Card.Header>
                                        <Card.Body>
                                            <TransactionChart logs={logsArray} orders={ordersArray}/>
                                        </Card.Body>
                                    </Card>

                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <Card.Header>
                                            <Text b h5 size={17} className="m-2">Transactions</Text>
                                        </Card.Header>
                                        <Card.Body>
                                            <TransactionTable data={transactionsArray}/>
                                        </Card.Body>
                                    </Card>

                                </MDBCol>
                                <MDBCol md="4">
                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <b className="mt-3 ml-3">Followers ({followArray.length})</b>
                                        <Card.Body>
                                            <List
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    onChange: page => {
                                                        console.log(page);
                                                    },
                                                    pageSize: 5,
                                                }}
                                                dataSource={followArray}
                                                renderItem={item => (
                                                    <List.Item
                                                        key={item.title}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <Avatar
                                                                    size="large"
                                                                    style={{ color: '#29327b', backgroundColor: '#ecf7fc' }}>
                                                                    {(userArray.find(x => (x.userID) === item.buyerId)?.Fullname)?.charAt(0)}
                                                                </Avatar>
                                                            }
                                                            title={
                                                                <a href={item.href}>
                                                                    {
                                                                        userArray.find(x => (x.userID) === item.buyerId)?.Fullname
                                                                    }
                                                                </a>
                                                            }
                                                            description={moment(item.createdAt).format("YYYY MMM DD HH:mm")}
                                                        /></List.Item>
                                                )}
                                            />
                                        </Card.Body>
                                    </Card>
                                    <Card className="my-2 font-JosefinSans overflow-hidden">
                                        <b className="mt-3 ml-3">Reviews ({reviewArray.length})</b>
                                        <Card.Body>
                                            <List
                                                itemLayout="vertical"
                                                size="large"
                                                pagination={{
                                                    onChange: page => {
                                                        console.log(page);
                                                    },
                                                    pageSize: 5,
                                                }}
                                                dataSource={reviewArray}
                                                renderItem={item => (
                                                    <List.Item
                                                        key={item.title}
                                                    >
                                                        <List.Item.Meta
                                                            avatar={
                                                                <Avatar
                                                                    size="large"
                                                                    style={{ color: '#29327b', backgroundColor: '#ecf7fc' }}>
                                                                    {(userArray.find(x => (x.userID) === item.buyerId)?.fullName)?.charAt(0)}
                                                                </Avatar>
                                                            }
                                                            title={
                                                                <a href={item.href}>
                                                                    {
                                                                        userArray.find(x => (x.userID) === item.buyerId)?.fullName
                                                                    }
                                                                </a>
                                                            }
                                                            description={
                                                                <div className="d-flex flex-row justify-content-between">
                                                                    <p>
                                                                        {moment(item.createdAt).format("YYYY MMM DD HH:mm")}
                                                                    </p>
                                                                    <p>
                                                                        <Rating value={item.rating} readOnly stars={5} cancel={false} />
                                                                    </p>
                                                                </div>
                                                            }
                                                        />
                                                        {item.description}
                                                    </List.Item>
                                                )}
                                            />
                                        </Card.Body>
                                    </Card>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </Content>
                </Layout>
            </Layout>
        </>
    )
}

export default SellerDetails;
