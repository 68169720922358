import React, {useEffect, useState } from 'react';
import {Chart} from "primereact/chart";
import {generatePath, useNavigate} from "react-router-dom";


type Props = {
    products: any[];
    logs: any[];
    toggleNumber: number;
};

type ChartType = {
    labels: string[];
    datasets: any[];
}

const moment = require('moment');
var previousSixMonths = function(){
    let n = 0;
    let arRet = [];

    for(; n < 6; n++)
        arRet.push(moment().subtract(n, 'months').format('MMMM'));
    return(arRet)
}();

const TopViewedProducts: React.FC<Props> = ({logs, products, toggleNumber})  => {
    var dataArray: ChartType = {
        labels: previousSixMonths,
        datasets: []
    }
    const navigate = useNavigate();
    const [basicData, setBasicData] = useState(dataArray);

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    onClick: (e: any, legendItem: any, legend: any) => {
                        navigate(generatePath('/products/:id', {id: basicData.datasets[legendItem.datasetIndex].productId}));
                    },
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };


        return {
            basicOptions
        }
    }
    const { basicOptions } = getLightTheme();

    function generateLightColorHex() {
        let color = "#";
        for (let i = 0; i < 3; i++)
            color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
        return color;
    }

    useEffect(() => {
        var tempArray: any[] = [];

        var logArray = logs.filter(x => x.logType === 'productView');

        products.map((product) => {
            var prodDataArray: any[] = [];
            previousSixMonths.map((month) => {
                var monthLogs = logArray.filter(x => (moment(x.date, "YYYY-MM-DDTh:mm:ss").format("MMMM") === month)
                    &&(x.objectID == product.productId));
                prodDataArray.push(monthLogs?.length);
            });
            tempArray.push(
                {
                    label: product.name,
                    productId: product.productId,
                    data: prodDataArray,
                    fill: false,
                    borderColor: generateLightColorHex(),
                    tension: .4
                }
            )
        });

        setBasicData({
            ...basicData,
            datasets: tempArray.sort((a,b) => sum(b.data) - sum(a.data)).slice(0, toggleNumber)
        });

    }, [logs, toggleNumber]);

    function sum(arr: any[]) {
        return arr.reduce( (x,y) => x+y, 0);
    }

    return (
        <div>
            <div className="card">
                <Chart type="line" data={basicData} options={basicOptions} />
            </div>
        </div>
    )
}

export default TopViewedProducts;
