import React, {useEffect, useRef, useState} from 'react';
import {useToast} from "@chakra-ui/react";
import {Form} from "antd";
import {Loading, Tooltip} from "@nextui-org/react";
import moment from "moment";
import Firefetch from "../../Firebase/Firefetch";
import { Select } from '@chakra-ui/react'
import { Image } from '@chakra-ui/react'
import holder from '../../images/plainHolder.jpg';
import {getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import Firebase from "../../Firebase/Firebase";
import { Modal, Button, Text, Input} from "@nextui-org/react";


type Props = {
    openDrawer: boolean;
    closeDrawer: (val: boolean) => void;
    section: any;
    products: any[];
    sellers: any[];
};

var storage = getStorage(Firebase);
const AddSectionContent: React.FC<Props> = ({openDrawer, closeDrawer, section, products, sellers}) => {
    const toast = useToast();
    const [contentForm] = Form.useForm();
    const [saveLoading, setSaveLoading] = useState(false);
    const [bannerFile, setBannerFile] = useState<string>();
    const [categoryFile, setCategoryFile] = useState<string>();
    const [file, setFile] = useState<Blob>();
    const fileUpload = useRef(null);

    useEffect(() => {
        console.log(section);
    }, [section]);

    const handleUpload = () => {
        // @ts-ignore
        fileUpload.current.click()
    };
    const bannerUploadFile = (e: any) => {
        setFile(e.target.files[0]);
        setBannerFile(URL.createObjectURL(e.target.files[0]));
    }

    const categoryUploadFile = (e: any) => {
        setFile(e.target.files[0]);
        setCategoryFile(URL.createObjectURL(e.target.files[0]));
    }

    // the function to add an image banner, categories or featured-products to the android app
    const addContent = (values : any) => {
        setSaveLoading(true);
        var ID = Array(20).fill("ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789abcdefghijklmnopqrstuvwxyz")
            .map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('');
        var sectionID = section?.id;
        var sectObj = section!;

        if(section?.contentType == "Images"){
            // if we are adding banners then,
            var object = {
                item : ""
            }
            uploadBytes(ref(storage, `sections/images/${ID}`), file!).then((item) => {
                getDownloadURL(item.ref)
                    .then((url) => {
                        object.item = url;
                        sectObj.content.push(object);
                        Firefetch.UpdateInDB("sections", sectionID, sectObj)
                            .then((result) => {
                                if(result == "success"){
                                    toast({
                                        position: 'top',
                                        title: 'Image Added!',
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    });
                                    setSaveLoading(false);
                                    contentForm.resetFields();
                                    setBannerFile("");
                                    closeDrawer(false);
                                }

                            });
                    }).catch(() =>{
                })



            });
        } else if(section?.contentType == "Products"){

            //if we are adding a category
            var obj = {
                item: values.product,
            };
            sectObj.content.push(obj);
            Firefetch.UpdateInDB("sections", sectionID, sectObj)
                .then((result) => {
                    if(result == "success"){
                        toast({
                            position: 'top',
                            title: 'Product added!',
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                        setSaveLoading(false);
                        contentForm.resetFields();
                        setCategoryFile("");
                        closeDrawer(false);
                    }

                });


        } else if(section?.contentType == "Sellers"){

            //if we are adding a category
            var obje = {
                item: values.seller,
            };
            sectObj.content.push(obje);
            Firefetch.UpdateInDB("sections", sectionID, sectObj)
                .then((result) => {
                    if(result == "success"){
                        toast({
                            position: 'top',
                            title: 'Seller added!',
                            status: 'success',
                            duration: 3000,
                            isClosable: true,
                        });
                        setSaveLoading(false);
                        contentForm.resetFields();
                        setCategoryFile("");
                        closeDrawer(false);
                    }

                });

        }
    }

    return (
        <>
            <Modal
                closeButton
                aria-labelledby="modal-title"
                open={openDrawer}
                onClose={() => {closeDrawer(false)}}
            >
                <Modal.Header>
                    <Text b size={18}>
                        Add Content: {section?.contentType}
                    </Text>
                </Modal.Header>
                <Modal.Body className="overflow-hidden">
                    <Form
                        className="mt-2"
                        id="contentForm"
                        form={contentForm}
                        layout={"vertical"}
                        onFinish={addContent}
                    >

                        { section?.contentType === "Sellers" ?
                            <Form.Item
                                name="seller"
                                label={<b>Sellers</b>}
                                rules={[
                                    { required: true, message: 'Please select seller!' },
                                ]}
                            >
                                <Select placeholder='Select seller' className="mt-3">
                                    {sellers.map((item) => (
                                        <option value={item.sellerID}>
                                            <div className="d-flex flex-row">
                                                <img alt={item.name} src={item.imageUrl}/>
                                                <b>{item.fullName}</b>
                                            </div>
                                        </option>
                                    ))}
                                </Select>
                            </Form.Item>
                            :
                            null
                        }

                        { section?.contentType === "Products" ?
                            <Form.Item
                                name="product"
                                label={<b>Products</b>}
                                rules={[
                                    { required: true, message: 'Please select product!' },
                                ]}
                            >
                                <Select placeholder='Select product' className="mt-3">
                                    {products.map((item) => (
                                        <option value={item.productId}>
                                            <div className="d-flex flex-row">
                                                <img alt={item.name} src={item.imageUrl}/>
                                                <b>{item.name}</b>
                                            </div>
                                        </option>
                                    ))}
                                </Select>
                            </Form.Item>
                            :
                            null
                        }
                        { section?.contentType === "Images" ?
                            <Form.Item
                                name="image"
                                label={<b>Image</b>}
                                rules={[
                                    { required: true, message: 'Please select image!' },
                                ]}
                            >
                                <Tooltip content={"Add Image"} rounded color="primary">
                                    <input
                                        type="file"
                                        ref={fileUpload}
                                        accept="image/*"
                                        onChange={bannerUploadFile}
                                        style={{ opacity: "0" }}
                                    />
                                    <Image
                                        width={300}
                                        height={"50%"}
                                        src={bannerFile}
                                        className="d-flex align-self-center"
                                        fallbackSrc={holder}
                                        onClick={() => {
                                            handleUpload()
                                        }}
                                        alt='profile'
                                    />
                                </Tooltip>

                            </Form.Item>
                            :
                            null
                        }


                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button auto type='submit'
                            form="contentForm">
                        {
                            saveLoading ?
                                <Loading type="points-opacity" color="currentColor" size="sm" />
                                :
                                <>SAVE</>
                        }

                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default AddSectionContent;
